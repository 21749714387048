@import '../styles/design_system';

mat-dialog-container {
  font: 400 16px/24px $default-font;

  h1 {
    @include heading-small;
    font-weight: 500;
    grid-column: 1;
    align-self: center;
    padding: 10px 20px 10px 20px;
    border-bottom: 1px solid $neutrals-gray-4;
    background: $brand-colors-primary-dark;
    color: #fff;
    margin: 0 0 0 0;
  }

  .container {
    padding: 24px;
  }

  section {
    margin-bottom: 15px;
  }

  label {
    @include text-default-weight-medium;
    margin: 0;
  }

  input, textarea {
    @include text-default-weight-regular;
    padding: 4px;
    margin: 0;
    width: 100%;
  }

  p.error {
    @include text-small-weight-medium;
    margin: 3px 0 0 0;
  }

  .actions{
    display: flex;
    justify-content: space-between;
  }
  button, .mdc-button, .mat-mdc-button {
    background: $brand-colors-primary-green;
    color: #fff !important;
  }
}
