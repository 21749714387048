@use '@angular/material' as mat;
@include mat.core();
@import './design_system';

html, body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font: 400 16px/24px $default-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
  color: #595a5a;
}

*, *:before, *:after {
  box-sizing: inherit;
}

intelligence-vault {
  display: grid;
  grid-template-rows: 36px calc(100vh - 36px);
  height: 100vh;

  #navigation {
    height: 36px;
  }

  #main-container {
    height: calc(100vh - 36px);
    overflow: hidden;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
  }

  a {
    color: $brand-colors-primary-green;
  }
}

button {
  cursor: pointer;
}

.cdk-overlay-container {
  .mat-button-toggle {
    font-size: 14px;

    .mat-button-toggle-appearance-standard, .mat-button-toggle-label-content {
      line-height: 24px;
      text-align: left;
    }

    .mat-button-toggle-appearance-standard:hover, .mat-button-toggle-label-content:hover {
      background: $neutrals-gray-12;
    }
  }

  .mat-button-toggle-checked, .mat-button-toggle-checked:hover {
    background: $brand-colors-primary-dark;
    color: #fff;

    .mat-button-toggle-label-content:hover {
      background: $brand-colors-primary-dark;
    }
  }
}

mat-snack-bar-container {
  background: #fff;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background {
  border-color: $brand-colors-primary-green !important;
  background-color: $brand-colors-primary-green !important;
  color: #fff !important;
}

.mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: $brand-colors-primary-green !important;
  color: #fff !important;
}

.mdc-radio__outer-circle, .mdc-radio__inner-circle {
  border-color: $brand-colors-primary-green !important;
}

mat-calendar {
  background: #fff;
  font-size: 12px;

  .mat-calendar-content {
    padding: 0px 30px 8px 30px;
  }

  .mat-calendar-previous-button::after, .mat-calendar-next-button::after {
    margin: 9px;
  }

  .mat-mdc-icon-button {
    height: var(--mdc-icon-button-state-layer-size, 26px);
    width: var(--mdc-icon-button-state-layer-size, 26px);
    color: var(--mdc-icon-button-icon-color, inherit);
  }
}



