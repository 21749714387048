@import './styles/variables';
@import './styles/design_system';
@import './styles/fonts';
@import './styles/base';
@import './styles/angular_material';
@import './styles/scrollbar';
@import './styles/forms';
@import './styles/dialogs';

// FOR DEMO ONLY THIS NEEDS TO BE FIXED / REMOVED
iv-chatbot{
  display: block;
  height: calc(100vh - 370px);
}
